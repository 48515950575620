import React from "react";
import { NavLink } from "react-router-dom";
import styles from "../styles/Buttons.module.css";
import { useSelector } from "react-redux";

const Buttons = () => {
  const user = useSelector((state) => state.user);
  const userId = user?.telegram_id;

  return (
    <div className={styles.buttonsContainer}>
      <NavLink
        to="/"
        className={({ isActive }) =>
          `${styles.button} ${isActive ? styles.activeButton : ""}`
        }
      >
        <i className="fas fa-home"></i>
        Главная
      </NavLink>
      <NavLink
        to={`/mining/${userId}`}
        className={({ isActive }) =>
          `${styles.button} ${isActive ? styles.activeButton : ""}`
        }
      >
        <i className="fas fa-bolt"></i>
        Заработок
      </NavLink>
      <NavLink
        to={`/friends/${userId}`}
        className={({ isActive }) =>
          `${styles.button} ${isActive ? styles.activeButton : ""}`
        }
      >
        <i className="fas fa-users"></i>
        Друзья
      </NavLink>
      <div className={`${styles.button} ${styles.disabledButton}`}>
        <i className="fas fa-gamepad"></i>
        Games
      </div>
    </div>
  );
};

export default Buttons;
