import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios'; 
import { addUserCoins, setLastOnline } from '../store';
import styles from '../styles/OfflineEarningsModal.module.css';

const OfflineEarningsModal = ({ earnings, onClose }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const handleCollect = async () => {
    try {
      // Добавляем заработанные монеты к балансу
      dispatch(addUserCoins(Math.floor(earnings)));

      // Обновляем lastOnline на текущее время
      const now = Date.now();
      dispatch(setLastOnline(now));

      // Отправляем обновленные данные на сервер
      await axios.post('https://smetanavv.fvds.ru/api/collect-offline-earnings', {
        telegramId: user.telegram_id,
        earnings: Math.floor(earnings),
        lastOnline: now,
      });

      // Закрываем модальное окно
      onClose();
    } catch (error) {
      console.error('Ошибка при сборе оффлайн заработка:', error);
      alert('Не удалось собрать заработанные монеты. Попробуйте позже.');
    }
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <h3 className={styles.modalHeader}>Добро пожаловать обратно!</h3>
        <p className={styles.modalMessage}>
          Вы заработали <strong>{Math.floor(earnings)}</strong> монет за время вашего отсутствия.
        </p>
        <button onClick={handleCollect} className={styles.collectButton}>
          Забрать
        </button>
      </div>
    </div>
  );
};

export default OfflineEarningsModal;
