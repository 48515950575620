import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addFloatingNumber, removeFloatingNumber } from '../store';
import styles from '../styles/CoinDisplay.module.css';
import pawnImage from '../images/pawn.png';
import knightImage from '../images/knight.png';
import bishopImage from '../images/bishop.png';
import rookImage from '../images/rook.png';
import queenImage from '../images/queen.png';

const levelImages = [
  { level: 1, image: pawnImage },
  { level: 2, image: knightImage },
  { level: 3, image: bishopImage },
  { level: 4, image: rookImage },
  { level: 5, image: queenImage },
];

const calculateLevel = (earningsPerHour) => {
  if (earningsPerHour >= 3000) return 5;
  if (earningsPerHour >= 1000) return 4;
  if (earningsPerHour >= 500) return 3;
  if (earningsPerHour >= 200) return 2;
  if (earningsPerHour >= 100) return 1;
  return 1;
};

const calculateTapValue = (level) => {
  switch (level) {
    case 1:
      return 1;
    case 2:
      return 2;
    case 3:
      return 3;
    case 4:
      return 4;
    case 5:
      return 5;
    default:
      return 1;
  }
};

const CoinDisplay = ({ onTap }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [isShaking, setIsShaking] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const [showYuhuu, setShowYuhuu] = useState(false);

  const floatingNumbers = useSelector((state) => state.user.floatingNumbers);

  const level = calculateLevel(user.earnings_per_hour);
  const tapValue = calculateTapValue(level);
  const levelImage = levelImages.find((img) => img.level === level)?.image || pawnImage;

  const displayCoins = isNaN(user.coins) ? "0" : Math.floor(user.coins).toString();

  useEffect(() => {
    console.log('User coins in CoinDisplay:', displayCoins);
  }, [displayCoins]);

  const handleClick = (event) => {
    event.preventDefault(); 
    event.stopPropagation();
  
    if (navigator.vibrate) {
      navigator.vibrate(39); 
    }
    
    if (user.energy < tapValue) return;
  
    const rect = event.currentTarget.getBoundingClientRect();
    const clickX = event.clientX - rect.left;
    const clickY = event.clientY - rect.top;
  
    const newFloatingNumber = {
      id: Date.now(),
      value: clickCount % 5 === 0 ? '' : tapValue,
      top: clickY,
      left: clickX,
      isLightning: clickCount % 5 === 0,
    };
  
    dispatch(addFloatingNumber(newFloatingNumber));
  
    setIsShaking(true);
    setClickCount((prevCount) => prevCount + 1);
  
    setTimeout(() => {
      setIsShaking(false);
    }, 500);
  
    setTimeout(() => {
      dispatch(removeFloatingNumber(newFloatingNumber.id));
    }, 1000);
  
    if (clickCount % 5 === 0) {
      setShowYuhuu(true);
      setTimeout(() => {
        setShowYuhuu(false);
      }, 1500);
    }
  
    onTap();
  };
  

  const handleContextMenu = (event) => {
    event.preventDefault();
  };

  return (
    <div 
      className={styles.coinContainer}
      onContextMenu={handleContextMenu} 
      onTouchStart={(e) => e.preventDefault()}
    >
      <div 
        className={`${styles.coinDisplay} ${isShaking ? styles.shake : ''}`} 
        onClick={handleClick} 
        onContextMenu={handleContextMenu}
      >
        <div className={styles.coinImageContainer}>
          <img 
            src={levelImage} 
            alt="Coins" 
            draggable="false" 
            onContextMenu={handleContextMenu}
          />
          {user.floatingNumbers.map((num) => (
            <div
              key={num.id}
              className={`${styles.floatUp} ${num.isLightning ? styles.lightning : ''}`}
              style={{ top: `${num.top}px`, left: `${num.left}px` }}
            >
              {num.value}
            </div>
          ))}
        </div>
      </div>
      {showYuhuu && (
        <div className={`${styles.yuhuuBubble} ${showYuhuu ? styles.show : styles.hide}`}>
          Пешки не орешки!
        </div>
      )}
    </div>
  );
};

export default CoinDisplay;
