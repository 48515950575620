import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import styles from "../styles/Mining.module.css";
import axios from "axios";
import TabNavigation from "./TabNavigation";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../store";
import useSocket from "../hooks/useSocket"; 

const Mining = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [cards, setCards] = useState([]);
  const userRef = useRef(user);

  const { requestUserCards, socket } = useSocket();

  useEffect(() => {
    userRef.current = user;
  }, [user]);

  useEffect(() => {
    const fetchUserAndCards = async () => {
      try {
        const { data: userData } = await axios.get(
          `https://smetanavv.fvds.ru/user/${userId}`
        );
        if (userData) {
          dispatch(setUser({
            ...user,
            earnings_per_hour: userData.earnings_per_hour,
            coins: userData.coins,
          }));
        }

        const userCards = userData.userCards
        .map((uc) => {
          const calculatedUpgradeCost = Math.floor(uc.card.baseCost * Math.pow(uc.card.costGrowthMultiplier, uc.level));

          return {
            ...uc.card,
            level: uc.level,
            upgradeCost: calculatedUpgradeCost, 
          };
        })
        .sort((a, b) => a.id - b.id);

        setCards(userCards);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchUserAndCards();
  }, [userId, dispatch]);

  useEffect(() => {
    // Запрашиваем карточки через сокет, если он подключен
    if (socket) {
      requestUserCards();
    }

    // Подписываемся на обновления карточек через сокет
    socket?.on("cardsUpdated", (updatedCards) => {
      console.log("Обновленные карточки получены через сокет:", updatedCards);
      setCards(updatedCards);
    });

    return () => {
      // Отключаем обработчик при размонтировании компонента
      socket?.off("cardsUpdated");
    };
  }, [socket, requestUserCards]);

  const handleUpgrade = async (cardId) => {
    console.log(`Начало улучшения карточки: ${cardId}`);

    try {
      const response = await axios.post(
        "https://smetanavv.fvds.ru/api/upgrade",
        {
          telegramId: user?.telegram_id,
          cardId,
        }
      );

      console.log(`Ответ сервера:`, response.data);

      if (!response.data.success) {
        console.error(`Ошибка в ответе сервера: ${response.data.message}`);
        throw new Error(response.data.message);
      }

      const updatedUser = response.data.user;
      console.log(`Данные обновленного пользователя:`, updatedUser);

      const updatedCards = updatedUser.userCards
        .map((uc) => ({
          ...uc.card,
          level: uc.level,
          upgradeCost: uc.upgradeCost,
        }))
        .sort((a, b) => a.id - b.id);

      console.log(`Обновленный список карточек:`, updatedCards);

      dispatch(setUser(updatedUser));
      setCards(updatedCards);

      console.log(`Карточка успешно улучшена: ${cardId}`);
    } catch (error) {
      console.error(`Ошибка при улучшении карточки с ID ${cardId}:`, error.message);
      console.error(`Статус ответа: ${error.response?.status}`);
      console.error(`Данные ошибки:`, error.response?.data);
    }
  };

  return (
    <div className={styles.outerContainerMining}>
      <TabNavigation />

      <div className={styles.miningContainer}>
        {Array.isArray(cards) && cards.length > 0 ? (
          cards.map((card) => {
            const currentLevel = card.level;
            const maxLevel = card.maxLevel || 10;

            const upgradeCost =
              currentLevel >= maxLevel
                ? "Макс. уровень"
                : card.upgradeCost;

            const income =
              currentLevel > 0
                ? card.baseIncome + card.incomeGrowth * (currentLevel - 1)
                : 0;

            return (
              <div key={card.id} className={styles.taskCard}>
                <div className={styles.taskHeader}>
                  <div className={styles.taskImage}>
                    <img
                      src={`https://smetanavv.fvds.ru/uploads/${card.image}`}
                      alt={card.name}
                    />
                  </div>
                  <div className={styles.taskLevel}>{currentLevel} ур.</div>
                </div>
                <div className={styles.taskDetails}>
                  <p className={styles.taskName}>{card.name}</p>
                  <p className={styles.cardDescription}>
                    {card.description || ""}
                  </p>
                  <p className={styles.earningsPerHour}>
                    Доход в час: {income}
                  </p>
                  <button
                    onClick={() => handleUpgrade(card.id)}
                    disabled={
                      currentLevel >= maxLevel || user.coins < upgradeCost
                    }
                    className={
                      currentLevel >= maxLevel || user.coins < upgradeCost
                        ? styles.upgradeButtonDisabled
                        : styles.upgradeButton
                    }
                  >
                    {upgradeCost} монет
                  </button>
                </div>
              </div>
            );
          })
        ) : (
          <p>Нет карточек для отображения.</p>
        )}
      </div>
    </div>
  );
};

export default Mining;
