import React, { useEffect, useState, useRef } from "react";
import styles from "../styles/Daily.module.css";
import { useDispatch, useSelector } from "react-redux";
import TabNavigation from "./TabNavigation";
import pawnImage from "../images/pawn.png";
import knightImage from "../images/knight.png";
import bishopImage from "../images/bishop.png";
import rookImage from "../images/rook.png";
import queenImage from "../images/queen.png";
import axios from "axios";
import { setUser, setDailyStreak, setLastDailyReward } from "../store";
import useSocket from "../hooks/useSocket";

const levelImages = [
  pawnImage,
  knightImage,
  bishopImage,
  rookImage,
  queenImage,
];

const Daily = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [activeDay, setActiveDay] = useState(0);
  const [collectedDays, setCollectedDays] = useState([]);
  const [timer, setTimer] = useState("00:00:00");
  const [isCollectButtonDisabled, setIsCollectButtonDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const userId = user?.telegram_id;
  const { collectDailyReward, socket } = useSocket();
  const timerRef = useRef(null); 

  const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, '0');
    const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0');
    const seconds = String(totalSeconds % 60).padStart(2, '0');
    return `${hours}ч ${minutes}м ${seconds}с`;
  };

  const setupTimer = (nextRewardTime) => {
    console.log('Setting up timer with nextRewardTime:', nextRewardTime);
    const diff = nextRewardTime - Date.now();
    console.log('Time difference (ms):', diff);

    if (diff > 0) {
      setIsCollectButtonDisabled(true);
      setTimer(formatTime(diff));

      if (timerRef.current) {
        clearInterval(timerRef.current);
      }

      timerRef.current = setInterval(() => {
        const now = Date.now();
        const remainingTime = nextRewardTime - now;
        console.log('Remaining Time (ms):', remainingTime);

        if (remainingTime <= 0) {
          setIsCollectButtonDisabled(false);
          clearInterval(timerRef.current);
          timerRef.current = null;
          setTimer("00:00:00");
        } else {
          setTimer(formatTime(remainingTime));
        }
      }, 1000);
    } else {
      setIsCollectButtonDisabled(false);
      setTimer("00:00:00");
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`https://smetanavv.fvds.ru/api/user/${userId}`);
        const userData = response.data;

        console.log('Полученные данные пользователя:', userData);

        dispatch(setUser(userData));

        const lastReward = userData.last_daily_reward ? new Date(userData.last_daily_reward) : null;
        console.log('Last Reward:', lastReward);

        const nextRewardTime = lastReward ? new Date(lastReward.getTime() + 24 * 60 * 60 * 1000) : new Date();
        console.log('Next Reward Time:', nextRewardTime);

        setupTimer(nextRewardTime);
      } catch (error) {
        console.error("Ошибка при получении данных пользователя:", error);
        setErrorMessage("Не удалось загрузить данные. Попробуйте позже.");
      }
    };

    if (userId) {
      fetchUserData();
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [userId, dispatch]);

  useEffect(() => {
    if (user?.current_day_streak) {
      setActiveDay(user.current_day_streak);
      setCollectedDays(Array.from({ length: user.current_day_streak }, (_, i) => i));
    }
  }, [user?.current_day_streak]);

  useEffect(() => {
    if (!socket) return;

    const handleDailyRewardSuccess = (data) => {
      console.log('daily-reward-success received:', data);
      
      const updatedUser = {
        ...data.user,
        last_daily_reward: new Date(data.user.last_daily_reward),
      };
      
      console.log('Updated User:', updatedUser);

      dispatch(setUser(updatedUser));

      const nextRewardTime = new Date(updatedUser.last_daily_reward.getTime() + 24 * 60 * 60 * 1000);
      console.log('Next Reward Time after collecting:', nextRewardTime);
      
      setupTimer(nextRewardTime);
    };

    const handleDailyRewardError = (data) => {
      console.log('daily-reward-error received:', data);
      setErrorMessage(data.message);
      setTimer(data.remainingTime);
      setIsCollectButtonDisabled(true);
    };

    socket.on('daily-reward-success', handleDailyRewardSuccess);
    socket.on('daily-reward-error', handleDailyRewardError);

    return () => {
      socket.off('daily-reward-success', handleDailyRewardSuccess);
      socket.off('daily-reward-error', handleDailyRewardError);
    };
  }, [socket, dispatch]);

  useEffect(() => {
    console.log('Текущее состояние пользователя:', user);
  }, [user]);

  const handleCollect = () => {
    if (isCollectButtonDisabled) return;

    collectDailyReward();
  };

  return (
    <div className={styles.outerContainerDaily}>
      <TabNavigation />
      <div className={styles.header}>
        <h2>Заходи в игру каждый день и получай бонусы</h2>
      </div>
      <div className={styles.bonusGrid}>
        {[...Array(30).keys()].map((day) => (
          <div
            key={day}
            className={`${styles.bonusCard} ${day === activeDay ? styles.activeDay : ""} ${collectedDays.includes(day) ? styles.collectedDay : ""}`}
          >
            <div className={styles.dayNumber}>
              <span>День {day + 1}</span>
            </div>
            <div className={styles.bonusImage}>
              <img
                src={levelImages[day % levelImages.length]}
                alt={`Day ${day + 1}`}
              />
              {collectedDays.includes(day) && (
                <div className={styles.checkmark}>✓</div>
              )}
            </div>
            <div className={styles.bonusText}>
              <span>{(day + 1) * 1000} монет</span>
            </div>
          </div>
        ))}
      </div>

      {errorMessage && <div className={styles.error}>{errorMessage}</div>}
      <div className={styles.fixedButtonContainer}>
        <button
          className={styles.collectButton}
          onClick={handleCollect}
          disabled={isCollectButtonDisabled}
        >
          {isCollectButtonDisabled ? timer : "Получить"}
        </button>
      </div>
    </div>
  );
};

export default Daily;
