import React, { useEffect, useState, useCallback } from "react";
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { setUser } from '../store';
import axios from "axios";
import styles from '../styles/Boosters.module.css';
import useSocket from '../hooks/useSocket';
import Buttons from './Buttons';
import TabNavigation from './TabNavigation';
import fullEnergyImage from '../images/full_energy.png';
import energyReserveImage from '../images/energy-reserve.png';
import energyRecoveryImage from '../images/energy-recovery.png';

const Boosters = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  // Используем Redux для хранения cooldowns и usageCounts
  const [nextUpgradeCosts, setNextUpgradeCosts] = useState({});

  const { socket } = useSocket();

  const energyLevel = user.energy_level || 0;
  const upgradeCost = 15 * Math.pow(2, energyLevel);

  // Функция для извлечения cooldown_end из userUserBoosters
  const extractCooldowns = (userUserBoosters) => {
    const cooldownsData = {};
    userUserBoosters.forEach((ub) => {
      if (ub.booster_id === 'restore' && ub.cooldown_end) {
        cooldownsData['restore'] = new Date(ub.cooldown_end).getTime();
      }
    });
    return cooldownsData;
  };

  // Функция для извлечения usageCounts из userUserBoosters
  const extractUsageCounts = (userUserBoosters) => {
    const counts = {};
    userUserBoosters.forEach((ub) => {
      if (ub.booster_id === 'restore') {
        counts['restore'] = ub.usage_count;
      }
    });
    return counts;
  };

  // Локальное состояние для cooldowns и usageCounts
  const [cooldowns, setCooldowns] = useState({});
  const [usageCounts, setUsageCounts] = useState({});

  // Обработка загрузки данных о бустерах пользователя
  useEffect(() => {
    const fetchUserBoosters = async () => {
      try {
        const { data } = await axios.get(`https://smetanavv.fvds.ru/user/${userId}`);
        const userUserBoosters = data.userUserBoosters || [];

        const counts = extractUsageCounts(userUserBoosters);
        const cooldownsData = extractCooldowns(userUserBoosters);

        const costs = {};
        const baseCost = 30;
        const energyRecoveryLvl = data.energy_recovery_level || 0;
        costs['energy_recovery'] = baseCost * Math.pow(2, energyRecoveryLvl);

        setUsageCounts(counts);
        setCooldowns(cooldownsData);
        setNextUpgradeCosts(costs);

        dispatch(setUser({
          ...user,
          userCards: data.userCards,
          userUserBoosters: data.userUserBoosters,
          energy_level: data.energy_level,
          energy_recovery_level: data.energy_recovery_level,
          max_energy: data.max_energy,
          coins: Math.max(user.coins, data.coins),
        }));
      } catch (error) {
        console.error('Ошибка при получении данных о бустерах пользователя:', error);
      }
    };

    if (userId) {
      fetchUserBoosters();
    }
  }, [userId, dispatch]);

  // Обновление локального состояния при изменении Redux-состояния бустеров
  useEffect(() => {
    const userUserBoosters = user.userUserBoosters || [];
    const counts = extractUsageCounts(userUserBoosters);
    const cooldownsData = extractCooldowns(userUserBoosters);

    setUsageCounts(counts);
    setCooldowns(cooldownsData);
  }, [user.userUserBoosters]);

  // Функция для обработки применения бустера
  const handleApplyBooster = useCallback(async (boosterId) => {
    try {
      const response = await axios.post('https://smetanavv.fvds.ru/api/boosters/apply', {
        telegramId: userId,
        boosterId,
      });
      const data = response.data;

      if (data.success) {
        dispatch(setUser({
          ...user,
          ...data.user,
          coins: data.user.coins,
          energy: data.user.energy,
          energy_level: data.user.energy_level,
          energy_recovery_level: data.user.energy_recovery_level,
          max_energy: data.user.max_energy,
        }));

        // Обновляем usageCounts и cooldowns из userUserBoosters
        if (data.userUserBoosters) {
          const updatedUserBoosters = data.userUserBoosters.filter(ub => ub.booster_id === boosterId);
          updatedUserBoosters.forEach((ub) => {
            setUsageCounts((prev) => ({
              ...prev,
              [boosterId]: ub.usage_count || prev[boosterId],
            }));

            if (ub.cooldown_end) {
              const cooldownEnd = new Date(ub.cooldown_end).getTime();
              setCooldowns((prev) => ({
                ...prev,
                [boosterId]: cooldownEnd,
              }));
            }
          });
        }

        // Обновляем стоимость улучшения
        if (boosterId === 'energy_recovery') {
          setNextUpgradeCosts((prev) => ({
            ...prev,
            [boosterId]: 30 * Math.pow(2, data.user.energy_recovery_level),
          }));
        } else if (boosterId === 'increase_energy') {
          setNextUpgradeCosts((prev) => ({
            ...prev,
            [boosterId]: 15 * Math.pow(2, data.user.energy_level),
          }));
        }
      } else {
        console.error('Ошибка при применении бустера:', data.message);
      }
    } catch (error) {
      console.error(
        'Ошибка при применении бустера:',
        error.response?.data?.message || error.message
      );
    }
  }, [userId, dispatch, user]);

  // Функция для получения оставшегося времени колддауна
  const getTimeRemaining = (timestamp) => {
    const now = Date.now();
    const distance = timestamp - now;
    if (distance <= 0) return null;
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    return `${hours}ч ${minutes}м ${seconds}с`;
  };

  // Таймер для обновления состояния колддауна каждую секунду
  useEffect(() => {
    const timer = setInterval(() => {
      setCooldowns((prevCooldowns) => {
        const newCooldowns = { ...prevCooldowns };
        let updated = false;
        Object.keys(newCooldowns).forEach((boosterId) => {
          if (Date.now() >= newCooldowns[boosterId]) {
            delete newCooldowns[boosterId];
            updated = true;
          }
        });
        return updated ? newCooldowns : prevCooldowns;
      });
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div>
      <Buttons activePage="Boosters" onButtonClick={() => { }} />
      <TabNavigation />
      <div className={styles.boostersContainer}>
        <h2 className={styles.title}>Покупай улучшения и увеличивай свой доход</h2>
        <div className={styles.section}>
          <div className={styles.boosterCard}>
            <div className={styles.boosterImageContainer}>
              <img className={styles.boosterImage} src={fullEnergyImage} alt="Полный запас энергии" />
            </div>
            <div className={styles.boosterDetailsContainer}>
              <p className={styles.boosterName}>Полный запас энергии</p>
              <p className={styles.boosterProgress}>
                {Math.max(0, 5 - (usageCounts['restore'] || 0))} из 5
              </p>
            </div>
            <div className={styles.boosterButtonContainer}>
              <button
                className={styles.applyButton}
                onClick={() => handleApplyBooster('restore')}
                disabled={
                  (cooldowns['restore'] && Date.now() < cooldowns['restore']) ||
                  (usageCounts['restore'] || 0) >= 5
                }
              >
                {cooldowns['restore'] && Date.now() < cooldowns['restore']
                  ? getTimeRemaining(cooldowns['restore'])
                  : 'Применить'}
              </button>
            </div>
          </div>
        </div>

        <div className={styles.section}>
          <h3 className={styles.sectionTitle}>Усилители</h3>
          <div className={styles.boosterCard}>
            <div className={styles.boosterImageContainer}>
              <img className={styles.boosterImage} src={energyReserveImage} alt="Запас энергии" />
            </div>
            <div className={styles.boosterDetailsContainer}>
              <p className={styles.boosterName}>Запас энергии</p>
              <p className={styles.boosterLevel}>{user.energy_level || 0} ур.</p>
              <p className={styles.boosterEffect}>Макс энергия: {user.max_energy || 1000}</p>
            </div>
            <div className={styles.boosterButtonContainer}>
              <button
                className={styles.applyButton}
                onClick={() => handleApplyBooster('increase_energy')}
                disabled={user.energy_level >= 10 || user.coins < upgradeCost}
              >
                {upgradeCost} монет
              </button>
            </div>
          </div>

          <div className={styles.boosterCard}>
            <div className={styles.boosterImageContainer}>
              <img className={styles.boosterImage} src={energyRecoveryImage} alt="Восстановление энергии" />
            </div>
            <div className={styles.boosterDetailsContainer}>
              <p className={styles.boosterName}>Восстановление энергии</p>
              <p className={styles.boosterLevel}>{user.energy_recovery_level || 0} ур.</p>
              <p className={styles.boosterEffect}>
                +{(user.energy_recovery_level * 0.33).toFixed(2)} энергии в секунду
              </p>
            </div>
            <div className={styles.boosterButtonContainer}>
              <button
                className={styles.applyButton}
                onClick={() => handleApplyBooster('energy_recovery')}
                disabled={user.coins < (nextUpgradeCosts['energy_recovery'] || 30)}
              >
                {nextUpgradeCosts['energy_recovery']
                  ? `${nextUpgradeCosts['energy_recovery']} монет`
                  : '30 монет'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Boosters;
