import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import styles from "../styles/Friends.module.css";
import { setUser } from "../store";

const Friends = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const userId = user?.telegram_id;
  const [invitedFriends, setInvitedFriends] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchInvitedFriends = async () => {
      try {
        const { data } = await axios.get(
          `https://smetanavv.fvds.ru/api/invited-friends/${userId}`
        );
        setInvitedFriends(data);
      } catch (error) {
        console.error("Ошибка при получении списка друзей:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (userId) {
      fetchInvitedFriends();
    }
  }, [userId]);

  const handleInviteClick = () => {
    const telegramInviteLink = `https://t.me/PawnsTokenTap_bot?start=referral_${userId}`;
    const shareText = `Привет! Присоединяйся к игре: ${telegramInviteLink}`;

    if (navigator.share) {
      navigator
        .share({
          text: shareText,
        })
        .then(() => console.log("Ссылка успешно поделена"))
        .catch((error) => console.error("Ошибка при совместном использовании:", error));
    } else if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.ready();
      window.Telegram.WebApp.MainButton.hide();

      if (typeof window.Telegram.WebApp.shareText === "function") {
        window.Telegram.WebApp.shareText(shareText);
      } else {
        const shareUrl = `https://t.me/share/url?url=${encodeURIComponent(
          telegramInviteLink
        )}&text=${encodeURIComponent("Присоединяйся к игре!")}`;
        window.open(shareUrl, "_blank");
      }
    } else {
      const shareUrl = `https://t.me/share/url?url=${encodeURIComponent(
        telegramInviteLink
      )}&text=${encodeURIComponent("Присоединяйся к игре!")}`;
      window.open(shareUrl, "_blank");
    }
  };

  const handleCopyClick = () => {
    const referralLink = `https://t.me/PawnsTokenTap_bot?start=referral_${userId}`;
    navigator.clipboard
      .writeText(referralLink)
      .catch((err) => {
        console.error("Ошибка при копировании: ", err);
      });
  };

  return (
    <div className={styles.outerContainerFriends}>
      <div className={styles.titleSection}>
        <div className={styles.title}>Приглашай друзей</div>
        <div className={styles.subTitle}>Ты получишь 20% от их дохода</div>
      </div>
      <div className={styles.inviteSection}>
        <div className={styles.inviteContainer}>
          <div className={styles.giftIcon}>🎁</div>
          <p className={styles.inviteButton} onClick={handleInviteClick}>
            Пригласить друга
          </p>
          <div className={styles.bonusText}>
            +5000 для тебя и твоего друга
          </div>
        </div>
      </div>
      <div className={styles.friendsListSection}>
        <div className={styles.friendsListHeader}>
          Список ваших друзей{" "}
          <span className={styles.friendCount}>{invitedFriends.length}</span>
        </div>
        <div className={styles.friendsList}>
          {isLoading ? (
            <div>Загрузка...</div>
          ) : invitedFriends.length > 0 ? (
            invitedFriends.map((friend, index) => (
              <div key={friend.telegram_id} className={styles.friendCard}>
                <div className={styles.friendName}>
                  {index + 1}.{" "}
                  {friend.username ||
                    (friend.first_name && friend.last_name
                      ? `${friend.first_name} ${friend.last_name}`
                      : "Без имени")}
                </div>
                <div className={styles.friendEarnings}>
                  {friend.referralEarnings} монет
                </div>
              </div>
            ))
          ) : (
            <div className={styles.noFriends}>Список друзей пуст</div>
          )}
        </div>
      </div>
      <div className={styles.bottomNav}>
        <button className={styles.bottomNavButton} onClick={handleInviteClick}>
          Пригласить друга
        </button>
        <button className={styles.copyIcon} onClick={handleCopyClick}>
          <i className="fas fa-copy"></i>
        </button>
      </div>
    </div>
  );
};

export default Friends;
