import { useEffect, useRef, useCallback } from 'react';
import io from 'socket.io-client';
import { useDispatch, useSelector } from 'react-redux';
import { 
  setUserCoins, 
  setUserEnergy, 
  setUser, 
  setDailyStreak, 
  setLastDailyReward 
} from '../store'; 

let socketInstance = null; 

const useSocket = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const userRef = useRef(user?.telegram_id);

  useEffect(() => {
    if (!user || !user.telegram_id) return;

    if (socketInstance && userRef.current === user.telegram_id) {
      return;
    }

    if (socketInstance) {
      socketInstance.disconnect();
      socketInstance = null;
    }

    userRef.current = user.telegram_id;

    socketInstance = io("https://smetanavv.fvds.ru", {
      withCredentials: true,
    });

    socketInstance.on("connect", () => {
      console.log("Connected to server");
      socketInstance.emit("auth", { telegramId: user.telegram_id });
    });

    socketInstance.on("auth-success", (data) => {
      console.log("Authenticated with server");
    });

    socketInstance.on("coinsUpdated", (data) => {
      console.log(`Received coinsUpdated: coins=${data.coins}, energy=${data.energy}`);
      dispatch(setUserCoins(data.coins));
      dispatch(setUserEnergy(data.energy));
    });

    socketInstance.on("energyUpdated", (data) => {
      console.log(`Received energyUpdated: energy=${data.energy}`);
      dispatch(setUserEnergy(data.energy));
    });

    socketInstance.on("userUpdated", (updatedUser) => {
      dispatch(setUser((prevUser) => ({
        ...prevUser,
        ...updatedUser,
      })));
    });

    socketInstance.on("dailyStreakUpdated", (data) => {
      console.log(`Daily streak updated: current streak=${data.current_day_streak}, last reward=${data.last_daily_reward}`);
      dispatch(setDailyStreak(data.current_day_streak));
      dispatch(setLastDailyReward(new Date(data.last_daily_reward)));
    });

    socketInstance.on("cardsUpdated", (updatedCards) => {
      console.log("Received cardsUpdated:", updatedCards);
      dispatch(setUser((prevUser) => ({
        ...prevUser,
        userCards: updatedCards,
      })));
    });

    socketInstance.on("daily-reward-success", (data) => {
      console.log("Received daily-reward-success:", data);
      dispatch(setUser({
        ...user,
        coins: data.user.coins,
        current_day_streak: data.user.current_day_streak,
        last_daily_reward: new Date(data.user.last_daily_reward),
      }));
    });

    socketInstance.on("daily-reward-error", (data) => {
      console.log("Received daily-reward-error:", data);
    });

    socketInstance.on("boosterReset", ({ boosterId }) => {
      console.log(`Received boosterReset event for boosterId: ${boosterId}`);
      dispatch(setUser((prevUser) => ({
        ...prevUser,
        userUserBoosters: prevUser.userUserBoosters.map((ub) => 
          ub.booster_id === boosterId 
            ? { ...ub, usage_count: 0, cooldown_end: null }
            : ub
        ),
      })));
    });

    socketInstance.on("disconnect", () => {
      console.log("Disconnected from server");
    });

    socketInstance.on("connect_error", (err) => {
      console.error("Connection error:", err);
    });

    socketInstance.on("error", (err) => {
      console.error("Socket error:", err);
    });

    return () => {
      if (socketInstance) {
        socketInstance.disconnect();
        socketInstance = null;
      }
    };
  }, [user, dispatch]);

  const tap = useCallback(() => {
    if (socketInstance && user?.telegram_id) {
      socketInstance.emit("tap", {
        telegramId: user.telegram_id,
        count: 1,
      });
    }
  }, [user?.telegram_id]);

  const collectDailyReward = useCallback(() => {
    if (socketInstance && user?.telegram_id) {
      socketInstance.emit("collect-daily-reward", {
        telegramId: user.telegram_id,
      });
    }
  }, [user?.telegram_id]);

  const requestUserCards = useCallback(() => {
    if (socketInstance && user?.telegram_id) {
      socketInstance.emit("request-cards", {
        telegramId: user.telegram_id,
      });
    }
  }, [user?.telegram_id]);

  return { tap, collectDailyReward, requestUserCards, socket: socketInstance }; 
};

export default useSocket;
