import React, { useEffect, useState, useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query';
import "./styles/App.css";
import useAuth from "./hooks/useAuth";
import useSocket from "./hooks/useSocket";
import axios from "axios";

import Header from "./components/Header";
import MainDisplay from "./components/MainDisplay";
import CoinDisplay from "./components/CoinDisplay";
import EnergyDisplay from "./components/EnergyDisplay";
import Buttons from "./components/Buttons";
import Mining from "./components/Mining";
import Daily from "./components/Daily";
import Friends from "./components/Friends";
import LoadingPage from "./components/LoadingPage";
import Boosters from './components/Boosters';
import OfflineEarningsModal from './components/OfflineEarningsModal'; 

import { useDispatch, useSelector } from 'react-redux';
import { setUser, setLastOnline, addEnergy, setMaxEnergy } from './store';

export const calculateMaxEnergy = (energyLevel) => {
  return 1000 + energyLevel * 500;
};

export const calculateTapValue = (level) => {
  switch (level) {
    case 1:
      return 1;
    case 2:
      return 2;
    case 3:
      return 3;
    case 4:
      return 4;
    case 5:
      return 5;
    default:
      return 1;
  }
};

export const calculateLevel = (earningsPerHour) => {
  if (earningsPerHour >= 3000) return 5;
  if (earningsPerHour >= 1000) return 4;
  if (earningsPerHour >= 500) return 3;
  if (earningsPerHour >= 200) return 2;
  if (earningsPerHour >= 100) return 1;
  return 1;
};

const AppContent = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { tap } = useSocket();
  const { isAuthenticated, loading } = useAuth();
  const [isLoadingPage, setIsLoadingPage] = useState(true);

  const [showOfflineEarnings, setShowOfflineEarnings] = useState(false);
  const [offlineEarnings, setOfflineEarnings] = useState(0);

  const userRef = useRef(user);
  const energyRecoveryFractionRef = useRef(0);

  // Обновление ссылки на пользователя при изменении состояния
  useEffect(() => {
    userRef.current = user;
  }, [user]);

  // Таймер для загрузочной страницы
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoadingPage(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);


  // Функция обработки нажатия "тап"
  const handleTap = async () => {
    const level = calculateLevel(user.earnings_per_hour);
    const tapValue = calculateTapValue(level);

    if (user.energy < tapValue) return;

    // Обновляем монеты и энергию пользователя
    const updatedCoins = (parseFloat(user.coins) || 0) + tapValue;
    const updatedEnergy = Math.max(0, user.energy - tapValue);

    const updatedUser = {
      ...user,
      coins: updatedCoins,
      coinsLastUpdated: Date.now(),
      energy: updatedEnergy,
      energyLastUpdated: Date.now(),
    };

    // Обновляем состояние пользователя
    dispatch(setUser(updatedUser));

    // Немедленно отправляем обновленные данные на сервер
    try {
      await axios.post('https://smetanavv.fvds.ru/api/update-coins', {
        telegramId: user.telegram_id,
        coins: updatedCoins,
        energy: updatedEnergy,
        coinsLastUpdated: updatedUser.coinsLastUpdated,
        energyLastUpdated: updatedUser.energyLastUpdated,
      });
    } catch (error) {
      console.error('Ошибка при обновлении монет на сервере:', error);
    }

    // Отправка на сервер через сокет
    tap();
  };

  // Автоматически отправляем данные на сервер при изменении coins или energy
  useEffect(() => {
    if (user.telegram_id && (user.coinsLastUpdated || user.energyLastUpdated)) {
      axios.post('https://smetanavv.fvds.ru/api/update-coins', {
        telegramId: user.telegram_id,
        coins: user.coins,
        energy: user.energy,
        coinsLastUpdated: user.coinsLastUpdated,
        energyLastUpdated: user.energyLastUpdated,
      }).catch(error => console.error('Ошибка при обновлении данных на сервере:', error));
    }
  }, [user.coins, user.energy, user.telegram_id, user.coinsLastUpdated, user.energyLastUpdated]);

  // Обработчик нажатия на кнопки навигации
  const onButtonClick = (page) => {
    const updatedUser = { ...user, activePage: page };
    dispatch(setUser(updatedUser));
  };

  const level = calculateLevel(user.earnings_per_hour);

  // Создание рефов для энергии и максимальной энергии
  const energyRef = useRef(user.energy);
  const maxEnergyRef = useRef(user.max_energy);

  // Обновление рефов при изменении энергии и максимальной энергии
  useEffect(() => {
    energyRef.current = user.energy;
    maxEnergyRef.current = user.max_energy;
  }, [user.energy, user.max_energy]);

  // Интервал для регенерации энергии каждую секунду
  useEffect(() => {
    const energyInterval = setInterval(() => {
      if (energyRef.current < maxEnergyRef.current) {
        const recoveryRate = 1 + (user.energy_recovery_level * 0.33);
        energyRecoveryFractionRef.current += recoveryRate;

        const energyToAdd = Math.floor(energyRecoveryFractionRef.current);
        if (energyToAdd > 0) {
          const newEnergy = Math.min(energyRef.current + energyToAdd, maxEnergyRef.current);
          energyRecoveryFractionRef.current -= energyToAdd;

          const updatedUser = {
            ...userRef.current,
            energy: newEnergy,
            energyLastUpdated: Date.now(),
          };
          dispatch(setUser(updatedUser));

          axios.post('https://smetanavv.fvds.ru/api/update-coins', {
            telegramId: userRef.current.telegram_id,
            energy: newEnergy,
            energyLastUpdated: updatedUser.energyLastUpdated,
          }).catch(error => console.error('Ошибка при обновлении энергии на сервере:', error));
        }
      }
    }, 1000); 

    return () => clearInterval(energyInterval);
  }, [user.energy_recovery_level, user.max_energy, dispatch]);



  // Обновление максимальной энергии при изменении уровня энергии
  useEffect(() => {
    const newMaxEnergy = calculateMaxEnergy(user.energy_level);
    if (user.max_energy !== newMaxEnergy) {
      dispatch(setMaxEnergy(newMaxEnergy));
    }
  }, [user.energy_level, dispatch]);

  // Логирование текущей энергии для отладки
  useEffect(() => {
    console.log('Current Energy:', user.energy);
  }, [user.energy]);

  // Обработка оффлайн заработка на основе времени последнего онлайн
  useEffect(() => {
    if (!user.lastOnline) {
      const now = Date.now();
      dispatch(setLastOnline(now));
      console.log(`Устанавливаем initial lastOnline: ${now}`);
      return;
    }
  
    const now = Date.now();
    const lastOnline = Number(new Date(user.lastOnline));
    const timeDifference = now - lastOnline;
  
    console.log(`lastOnline: ${lastOnline}, now: ${now}, timeDifference: ${timeDifference}`);
  
    const MIN_OFFLINE_TIME = 3 * 60 * 1000; // 3 минуты
    const MAX_OFFLINE_TIME = 3 * 60 * 60 * 1000; // 3 часа
  
    let effectiveTime = 0;
  
    if (timeDifference >= MIN_OFFLINE_TIME && timeDifference <= MAX_OFFLINE_TIME) {
      effectiveTime = timeDifference;
    } else if (timeDifference > MAX_OFFLINE_TIME) {
      effectiveTime = MAX_OFFLINE_TIME;
    }
  
    if (effectiveTime >= MIN_OFFLINE_TIME) {
      const hoursOffline = effectiveTime / (1000 * 60 * 60);
      const earnings = user.earnings_per_hour * hoursOffline;
      console.log(`Заработок за оффлайн: ${earnings}`);
      setOfflineEarnings(Math.floor(earnings));
      setShowOfflineEarnings(true);
    } else {
      console.log('Разница во времени меньше минимального порога. Модальное окно не будет показано.');
    }
  }, [user.lastOnline, user.earnings_per_hour, dispatch]);

  // Обработчик события выгрузки страницы
  useEffect(() => {
    const handleBeforeUnload = () => {
      const timestamp = Date.now();
      console.log(`Обновление lastOnline при выгрузке: ${timestamp}`);
      dispatch(setLastOnline(timestamp));
      
      // Отправка на сервер
      axios.post("https://smetanavv.fvds.ru/api/update-coins", {
        telegramId: userRef.current?.telegram_id,
        coins: userRef.current?.coins,
        energy: userRef.current?.energy,
        lastOnline: timestamp,
        coinsLastUpdated: userRef.current?.coinsLastUpdated,
        energyLastUpdated: userRef.current?.energyLastUpdated,
      }).catch(error => console.error("Ошибка при обновлении lastOnline на сервере:", error));
    };
  
    window.addEventListener('beforeunload', handleBeforeUnload);
  
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      const timestamp = Date.now();
      console.log(`Обновление lastOnline при размонтировании компонента: ${timestamp}`);
      dispatch(setLastOnline(timestamp));
      
      // Отправка на сервер
      axios.post("https://smetanavv.fvds.ru/api/update-coins", {
        telegramId: userRef.current?.telegram_id,
        coins: userRef.current?.coins,
        energy: userRef.current?.energy,
        lastOnline: timestamp,
        coinsLastUpdated: userRef.current?.coinsLastUpdated,
        energyLastUpdated: userRef.current?.energyLastUpdated,
      }).catch(error => console.error("Ошибка при обновлении lastOnline на сервере:", error));
    };
  }, [dispatch]);

  // Обновление монет каждую секунду
  useEffect(() => {
    let lastUpdateTime = Date.now();
  
    const updateCoins = () => {
      const earningsPerHour = Number(userRef.current?.earnings_per_hour) || 0;
      const coins = Number(userRef.current?.coins) || 0;
  
      const currentTime = Date.now();
      const elapsedSeconds = (currentTime - lastUpdateTime) / 1000;
      lastUpdateTime = currentTime;
  
      const earnings = (earningsPerHour / 3600) * elapsedSeconds;
      const updatedCoins = coins + earnings;
  
      console.log(`Earnings: ${earnings}, Updated Coins: ${updatedCoins}`);
  
      if (!isNaN(updatedCoins)) {
        const updatedUser = {
          ...userRef.current,
          coins: updatedCoins,
          coinsLastUpdated: Date.now(),
        };
        dispatch(setUser(updatedUser));
  
        // Немедленная отправка обновленных монет на сервер
        axios.post("https://smetanavv.fvds.ru/api/update-coins", {
          telegramId: userRef.current?.telegram_id,
          coins: updatedCoins,
          coinsLastUpdated: updatedUser.coinsLastUpdated,
        }).catch(error => console.error("Ошибка при обновлении данных на сервере:", error));
      } else {
        console.error("Обновленные монеты не являются числом", {
          updatedCoins,
          earnings,
          coins,
        });
      }
    };
  
    const intervalId = setInterval(updateCoins, 1000); // Обновляем каждую секунду
  
    return () => {
      clearInterval(intervalId);
      // Отправка последних данных при размонтировании
      axios.post("https://smetanavv.fvds.ru/api/update-coins", {
        telegramId: userRef.current?.telegram_id,
        coins: userRef.current?.coins,
        coinsLastUpdated: userRef.current?.coinsLastUpdated,
      }).catch(error => console.error("Ошибка при отправке данных при размонтировании:", error));
    };
  }, []);

  if (loading) {
    return <div>Authenticating...</div>;
  }

  if (!isAuthenticated) {
    return <div>Authentication failed</div>;
  }

  if (!user) {
    return <div>Loading user data...</div>;
  }

  if (isLoadingPage) {
    return <LoadingPage />;
  }

  return (
    <div className="app">
      <Header
        username={user.username || "Guest"}
        coins={user.coins}
        earningsPerHour={user.earnings_per_hour || 0}
        telegramId={user.telegram_id}
      />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <MainDisplay
                energy={user.energy || "0"}
                maxEnergy={user.max_energy}
                coinsPerTap={calculateTapValue(level)}
                coinsPerHour={user.earnings_per_hour || 0}
              />
              <CoinDisplay
                coins={user.coins}
                onTap={handleTap}
                energy={user.energy}
              />
              <EnergyDisplay
                energy={user.energy || "0"}
                maxEnergy={user.max_energy}
              />
            </>
          }
        />
        <Route path="/mining/:userId" element={<Mining />} />
        <Route path="/daily/:userId" element={<Daily />} />
        <Route
          path="/boosters/:userId"
          element={
            <div style={{ backgroundColor: '#1C1C1E', minHeight: '100vh' }}>
              <Boosters />
            </div>
          }
        />
        <Route path="/friends/:userId" element={<Friends />} />
      </Routes>
      <Buttons
        activePage={user.activePage}
        onButtonClick={onButtonClick}
      />
      {showOfflineEarnings && (
        <OfflineEarningsModal 
          earnings={offlineEarnings} 
          onClose={() => setShowOfflineEarnings(false)} 
        />
      )}
    </div>
  );
};

const App = () => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AppContent />
      </Router>
    </QueryClientProvider>
  );
};

export default App;
