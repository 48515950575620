// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #000000;
  color: white;
  padding: 20px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: white;
}

.roadmapList {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.roadmapItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  margin: 5px 0;
  background-color: #1f1f1f; 
  border-radius: 10px;
  width: 320px;
}

.stepName {
  font-size: 16px;
  color: #ffffff;
}

.checkmark {
  color: #00d100;
  font-size: 20px;
}

.next {
  background-color: #007bff;
  color: white;
  padding: 5px 12px;
  font-size: 14px;
  border-radius: 20px;
}

.soon {
  background-color: #800080; 
  color: white;
  padding: 5px 12px;
  font-size: 14px;
  border-radius: 20px;
}

.spinner {
  margin-top: 30px;
  font-size: 24px;
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/styles/LoadingPage.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;EACb,yBAAyB;EACzB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,UAAU;EACV,SAAS;EACT,WAAW;AACb;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB;EAClB,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,YAAY;AACd","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  height: 100vh;\n  background-color: #000000;\n  color: white;\n  padding: 20px;\n}\n\n.title {\n  font-size: 24px;\n  font-weight: bold;\n  margin-bottom: 20px;\n  color: white;\n}\n\n.roadmapList {\n  list-style-type: none;\n  padding: 0;\n  margin: 0;\n  width: 100%;\n}\n\n.roadmapItem {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 15px 20px;\n  margin: 5px 0;\n  background-color: #1f1f1f; \n  border-radius: 10px;\n  width: 320px;\n}\n\n.stepName {\n  font-size: 16px;\n  color: #ffffff;\n}\n\n.checkmark {\n  color: #00d100;\n  font-size: 20px;\n}\n\n.next {\n  background-color: #007bff;\n  color: white;\n  padding: 5px 12px;\n  font-size: 14px;\n  border-radius: 20px;\n}\n\n.soon {\n  background-color: #800080; \n  color: white;\n  padding: 5px 12px;\n  font-size: 14px;\n  border-radius: 20px;\n}\n\n.spinner {\n  margin-top: 30px;\n  font-size: 24px;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `container`,
	"title": `title`,
	"roadmapList": `roadmapList`,
	"roadmapItem": `roadmapItem`,
	"stepName": `stepName`,
	"checkmark": `checkmark`,
	"next": `next`,
	"soon": `soon`,
	"spinner": `spinner`
};
export default ___CSS_LOADER_EXPORT___;
