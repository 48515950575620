import { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUser } from '../store';
import axios from 'axios';

const useAuth = () => {
  const dispatch = useDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true); 

  const handleAuth = useCallback(async () => {
    try {
      const telegramData = window.Telegram.WebApp.initDataUnsafe;
  
      if (!telegramData || !telegramData.user) {
        console.error("No Telegram data available");
        setLoading(false);
        return;
      }
  
      const telegramId = telegramData.user.id;
      const telegramUsername = telegramData.user.username || telegramData.user.first_name || "TestUser";

      let inviterId = null;

      // Извлекаем inviterId из start_param
      if (telegramData.start_param && telegramData.start_param.startsWith('referral_')) {
        inviterId = telegramData.start_param.replace('referral_', '');
      }

      // Всегда запрашиваем актуальные данные пользователя с сервера
      let response;

      if (inviterId) {
        response = await axios.post('https://smetanavv.fvds.ru/api/referral', {
          telegramId,
          referralId: inviterId,
          username: telegramUsername,
        });
      } else {
        response = await axios.post('https://smetanavv.fvds.ru/api/auth', {
          telegramId: telegramId,
          username: telegramUsername,
        });
      }

      dispatch(setUser(response.data.user));
      setIsAuthenticated(true);
    } catch (error) {
      console.error("Authentication failed:", error.message);
      setIsAuthenticated(false);
    } finally {
      setLoading(false);
    }
  }, [dispatch]);
  

  useEffect(() => {
    if (!isAuthenticated && loading) {
      handleAuth();
    }
  }, [isAuthenticated, loading, handleAuth]);

  return { isAuthenticated, loading };
};

export default useAuth;
